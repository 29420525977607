<template>
    <section id="workingArea">
        <div class="swapBtn_sm d-flex  align-items-center">
                <router-link to="/starcards" class="btn1">
                Mint
                </router-link>
                <router-link to="/holders">
                ?Fee
                </router-link>
        </div>
        <div id="tabSec" style="max-width:600px;">
            <div class="balance" style="max-width:600px; padding: 10px 0px 40px 0px;">
                <div class="sol text-center">
                    <img src="@/assets/images/mint.webp" style="width:95%; padding:0 15px;">
                </div>
                <div class="justify-center flex items-center">
                    <div class="row justify-center items-center q-my-sm" style="width:60%; padding-bottom:15px;">
                        <div class="col-sm-12 flex col-md-6">
                            <div class="label box-outlined text-white countlabel" style="font-size: 16px !important;">
                                Count :
                            </div>
                            <div style="font-size: 16px !important;">
                                <q-select
                                    class="count"
                                    outlined dense
                                    v-model="mintAmount" 
                                    :options="MintAmounts" 
                                    style="width:70px;"
                                    :disabled="isReservedPeriod"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 text-white q-my-sm">
                            <span class="label mint_label" style="font-size: 16px !important;">Minted</span>
                            <span class="label mint_value"> {{totalSupply}}/1000</span>
                        </div>
                    </div>
                    <div class="mintBtn" style="width:80%">SOLD OUT</div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { BigNumber } from 'bignumber.js'
    export default {
        components: {
        },
        data() {
            return {
                mintAmount: 3,
                MintAmounts: [
                    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'
                ],
            }
        },
        mounted() {
        },
        computed: {      
            totalSupply() {
                if(BigNumber(this.$store.state.starCardsnft.totalSupply).isNaN())
                    return 0;
                return this.$store.state.starCardsnft.totalSupply;
            },
            maxTokencount() {
                if (this.$store.state.starCardsnft.status < 4) {
                    if (BigNumber(this.$store.state.starCardsnft.maxSubTokenCount).isNaN())
                        return 0;

                    let value = this.$store.state.starCardsnft.status;
                    if (value == 0)
                        value = 1;
                    return this.$store.state.starCardsnft.maxSubTokenCount * value;
                }
                else  {
                    if (BigNumber(this.$store.state.starCardsnft.maxTotalTokenCount).isNaN())
                        return 0;
                    return this.$store.state.starCardsnft.maxTotalTokenCount;
                }
            },
            isReservedPeriod() {
                return this.$store.state.starCardsnft.ReservedPeriod;
            }
        },
        methods: {
            mint() {
                this.$store.dispatch('mint', this.mintAmount)
            },
        }
    }
</script>
<style>
    .crypto {
        background:#000;
        border-radius: 10px;
    }
    .crypto .row {
        padding:0 !important;
        margin:0 !important;
    }
    .crypto .col {
        padding:0 !important;
        margin:0 !important;
    }

    .mintAmount {
        margin-left:5px;
        margin-top:10px;
        width:20%
    }
    .count .row {
        padding-left: 10px !important;
        padding-right:2px;
        padding-bottom: 8px;
        margin:0 !important;
        color:#39d0d8;
    }
    .count .q-field__control {
        border: 1px solid #39d0d84f !important;
        font-size: 14px;
    }
    .q-field {
        font-size: 14px;
    }

    .count .col {
        padding:0 !important;
        margin:0 !important;
        font-size: 14px !important;
    }

    .mint_label {
        font-size: 1rem;
        margin-right: 10px;
    }
    .mint_value {
        font-size: 1rem;
    }
</style>
